import React from 'react'
import { push } from 'gatsby-link'
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardFooter,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from 'reactstrap'
import Serbia from '../assets/img/serbia.jpg'

const NotFoundPage = () => (
  <Container>
    <Row>
      <Col className="forOhfor-col" sm={{ size: 6, offset: 3 }}>
        <Card>
          <CardBody>
            <CardTitle>404</CardTitle>
            <CardSubtitle className="forOhFor-subTitle">
              You Broke It
            </CardSubtitle>
            <CardImg
              className="forOhfor-img"
              top
              width="100%"
              src={Serbia}
              alt="Card image cap"
            />
          </CardBody>
          <CardFooter>
            <Button onClick={() => push('/')} block id="submit-button">
              Go back to mhooper72.com
            </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </Container>
)

export default NotFoundPage
